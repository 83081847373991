/** @format */

.flex-content-center {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
}

.underline {
    border-bottom: 1px solid gray;
    border-spacing: 15px;
}

pre {
    background: #f1f1f1;
    border-radius: 3px;
    padding: 20px;
}

/** activity */

.activities-list {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0px;
}
.activities-list li:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: 0px;
}
.activities-list li {
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    line-height: 34px;
}
.activities-list.no-bullets {
    list-style-type: none;
}

/** activity end */

/** invoices */

.invoice-type-container {
    margin: 20px 0px;
}

.invoice-type-container button {
    margin-right: 20px;
}

.invoice-item-list {
    margin-top: 15px;
}

.table-invoice {
    text-align: center;
}
/** invoices end */

/** asset */
.label-pointer {
    cursor: pointer;
}
/** asset end */

/** my entity */
.row-e-log {
    border-bottom: 1px solid #ddd;
}

.row-e-log:last-child {
    border-bottom: 0px solid #ddd;
}
/** my entity end */

/** change history */
.key-field {
    font-weight: bold;
    text-transform: capitalize;
}
/** change history end */

/** payment sample page **/
.amount-to-pay {
    background: #eee;
    border-radius: 4px;
    padding: 2em;
    display: block;
    h4 h2 {
        font-weight: normal;
        margin-bottom: 5em;
        display: block;
    }
}
/** payment sample page end **/

.rdw-editor-main {
    background-color: #ffffff;
    border: 1px solid #f3f6f9;
    border-radius: 7px;
    color: #3f4254;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    padding: 10px;
}

.table-invoice > tr > td {
    vertical-align: middle;
}
.table-invoice > tr > td > .form-group {
    margin-bottom: 0px !important;
}

.pointer {
    cursor: pointer;
}

.asset-underline {
    text-decoration: underline;
}

.pac-container {
    z-index: 10000;
}

.close {
    font-size: 24px !important;
}
