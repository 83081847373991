/** @format */

.table-box-header {
    // color: #1f921f;
    color: #fff !important;
    background-color: #d3ae36 !important;
    border: none !important;
}

.pagination-size {
    outline: none;
    padding: 8px 12px;
    border-radius: 2px;
    color: #3f4254;
}

.react-bootstrap-table {
    overflow-x: auto;
}

.react-bootstrap-table {
    th {
        outline: none;

        &.sortable {
            .svg-icon-sort {
                opacity: 0;
            }

            &:hover {
                cursor: pointer;

                .svg-icon-sort {
                    opacity: 1;
                }
            }
        }
    }

    .table.table-head-custom thead tr {
        .sortable-active {
            color: $primary !important;
        }
    }

    .borderless-table tr {
        border-top-style: hidden !important;

        td {
            padding: 0px;
        }
    }
}

.search-bar {
    border: 1px solid #d7dae3;
}

.table-slim {
    td {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 5px;
        padding-right: 5px;
        vertical-align: middle !important;
    }

    th {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 5px;
        padding-right: 5px;
    }
}

.row-valign-center {
    line-height: 1.4;
}

.table-row-custom tbody tr {
    &:hover {
        background-color: #dfeef9 !important;
    }
}
