/** @format */

.w100p {
  width: 100%;
}

.border-bottom {
  border-bottom: 2px solid rgb(0, 0, 0);
}

.dashboard-card {
  // background-color: #f3f3f380;
}

@media print {
  #print-button-container {
    display: none;

    button {
      display: none;
    }
  }
}

.disabled-link {
  pointer-events: none;
}
