/** @format */

.bg-footer {
    background-color: #d3ae36;
}

.procorp-footer {
    // padding-left: 21.563rem;
    background-color: #f4f4f4;
}

.procorp-copyright {
    // border-radius: 0 0 0 50px;
    // padding: 0.9375rem;
    text-align: center;
    margin: 0;
}
