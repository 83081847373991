/** @format */

.custom-breadcrumb {
  display: flex;
  background-color: transparent !important;
  margin: 0px;
  padding: 0.8rem 0rem 1rem 1rem;
}

.custom-breadcrumb-item {
  padding: 0px;
}
