/** @format */

.textalign-center {
    text-align: center;
}

.textalign-right {
    text-align: right;
}

.floating-button {
    // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: all 0.3s;
}

.accordion-header {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.PhoneInput {
    border: 1px solid #d7dae3;
    border-radius: 0.42rem;
    padding: 0 10px;
}

.PhoneInput input {
    border: 0px solid #d7dae3;
    color: #181c32;
    height: auto !important;
    display: block;
    width: 100%;
    padding: 0.65rem 1rem;
    font-size: 1.08rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3f4254;
    background-color: #ffffff;
    background-clip: padding-box;
    // border: 1px solid '#d7dae3';
    border-radius: 0.42rem;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-inline {
    display: inline !important;
}

.w-92 {
    width: 92% !important;
}

.bold-checkbox {
    font-weight: 800;
}

.pt0 {
    padding-top: 0px !important;
}

.password-field-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    position: relative;
}

.visibility-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #494b74;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 50%;
    min-width: 2.4rem;
    padding-right: 0.3em;
    margin: auto;
}

.password-field-container > .is-invalid {
    background-position: right calc(0.375em + 2.4rem) center;
}

.form-control-solid {
    border: 1px solid #d7dae3 !important;
    background-color: #ffffff !important;
    &:active,
    &.active,
    &:focus,
    &.focus {
        border: 1px solid #d3ae36 !important;
    }
    &.is-invalid {
        border: 1px solid #f64e60 !important;
    }
}

.file-preview-label {
    display: flex;
    align-items: center;
    border-radius: 0.42rem;
}

.PhoneInputInput {
    transition: $transition-input;
    outline: none;
    &:active,
    &.active,
    &:focus,
    &.focus {
        border: 1px solid #d3ae36 !important;
    }
    &.is-invalid {
        border: 1px solid #f64e60 !important;
    }
}
