/** @format */

.cardContainer {
    width: 800px;
    height: 100px;
    position: fixed;
    top: 10%;
    left: 30%;
    margin-top: -100px;
    margin-left: -100px;
}

.cardBox {
    background: white;
    width: 500px;
    border-radius: 2px;
    margin: 0 auto auto 250px;
    padding: 0px 0px 2px 0px;
}

.cardname,
.cardlastName,
.cardaddress,
.cardcompany,
.cardphone,
.cardeMail,
.cardwebSite {
    background: #ecf0f1;
    border: #ccc 1px solid;
    border-bottom: #ccc 2px solid;
    padding: 0.5px;
    width: 250px;
    color: #aaaaaa;
    margin-top: 0.5px;
    font-size: 1em;
    border-radius: 4px;
}
.cardDivv:hover {
    outline: 2px solid #d3ae36;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.cardSelected {
    outline: 2px solid #d3ae36;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.cardDivv {
    width: 27em;
    height: 15em;
    color: #777;
    background-color: #f0f0f0;
    margin: 1% auto 0 auto;
    border-radius: 0.4em;
    border-top-right-radius: 0.37em;
    border-bottom-right-radius: 0.37em;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    transition: box-shadow 200ms ease-in;
}

.cardDivv:before {
    content: '';
    background: url(https://img3.goodfon.com/wallpaper/nbig/2/e0/linii-abstrakciya-fon-tochki.jpg);
    background-position: 20% 50%;
    width: 12em;
    height: 15em;
    display: block;
    position: absolute;
}

.card1 {
    padding: 1em 2em 2em 14em;
}

.cardName {
    color: #008080;
    font-size: 1em;
    letter-spacing: 1px;
}

.cardCompanyName {
    color: #00ced1;
    font-size: 1em;
    margin-top: 0.2em;
}

.cardAddress,
.cardPhoneNumber,
.cardEmail,
.cardWebsite {
    font-family: 'Ubuntu Mono', monospace;
    margin: 0em;
    font-weight: 700;
    color: black;
}
