/** @format */

//
// Override global variables
//

// Theme colors
$primary: #d3ae36;
$primary-hover: #cea00b;
$primary-light: #e9faef;
$primary-inverse: #ffffff;

$tertiary: #ff7f00;
$tertiary-hover: #a74300;
$tertiary-light: #ffe9d0;
$tertiary-inverse: #ffffff;

$info: #6993ff;
$info-light: #e1e9ff;
$info-hover: #4a7dff;
$info-inverse: #ffffff;

$success: #24a7ff;
$success-hover: #096bcc;
$success-light: #dcf6ff;
$success-inverse: #f2fcff;
