/** @format */

//
// Layout Config
//

// Root Font Sizes
$root-font-size: 13px; // Root font size for desktop mode
$root-font-size-lg: 13px; // Root font size for tablet mode
$root-font-size-md: 12px; // Root font size for mobile mode

// Page Background Color
$page-bg: #ffffff !default; // #F3F6F9

// Page Paddings
$page-padding: (
  desktop: 25px,
  // Padding for desktop mode
    tablet-and-mobile: 15px // Padding for tablet and mobile modes,,,,,,,,,
) !default;

// Header Settngs
$header-config: (
  // Tablet & Mobile Modes
    tablet-and-mobile:
    (
      // Default Mode
        default:
        (
          height: 55px,
          bg-color: $gray-800,
          shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1)
        ),
      // Fixed Mode
        fixed:
        (
          zindex: 97,
          height: 55px
        )
    )
) !default;

// Aside
$aside-config: (
  // Aside
    base:
    (
      primary-width: 256px,
      // Aside's primary panel's width for desktop mode(see "aside-primary" class)
        secondary-width: 325px,
      // AsideAside's primary panel's width for desktop mode(see "aside-secondary" class)
        primary-width-tablet-and-mobile: 256px,
      // Aside's primary panel's width for mobile mode(see "aside-primary" class)
        secondary-width-tablet-and-mobile: 225px,
      // Aside's primary panel's width for mobile mode(see "aside-secondary" class)
        transition: all 0.3s ease,
      // General transition
        toggle-space: get($page-padding, desktop),
      // Aside's toggle button offset(see "aside-toggle" class)
        bg-color: $gray-800,
      // Aside's background color
        shadow: none,
      //5px 0px 10px rgba(70, 78, 95, 0.05), // Aside's box shadow
        zindex: 98,
      // Aside's z-index property
        scroll-width: 4px // Aside's custom scrollbar's width,,,,,,,,,
    ),
  offcanvas-mobile: (
    // Offcanvas Mobile
      width: 275px,
    zindex: 1001,
    bg-color: $gray-800,
    shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.75),
    overlay-bg-color: rgba(#000000, 0.1)
  )
) !default;

// Aside Menu
$aside-menu-config: (
  // Base parameters required to build the base menu(see mixin: src/sass/components/mixins/_menu-vertical.scss)
    base:
    (
      class: aside-menu,
      // Menu element class name
        parent-class: aside,
      // Menu parent aside element class name
        mode: general,
      // Menu responsive mode: general or tablet-and-mobile,,,,,,,,,
    ),
  build: (
    // Customize
      // layout
      layout:
      (
        // Default Mode
          default:
          (
            // Container
              self:
              (
                padding: 15px 0
              ),
            // Item
              item:
              (
                // Item
                  self:
                  (
                    margin: 0
                  ),
                // Link
                  link:
                  (
                    // Link
                      self:
                      (
                        height: 44px,
                        padding: 9px 25px,
                        border-radius: $border-radius
                      ),
                    // Icon
                      icon:
                      (
                        width: 35px,
                        svg-width: 23px,
                        font-size: 1.3rem
                      ),
                    // Bullet
                      bullet:
                      (
                        self: (
                          width: 15px
                        ),
                        dot: (
                          size: 4px
                        ),
                        line: (
                          width: 5px,
                          height: 1px
                        )
                      ),
                    // Text
                      text:
                      (
                        font-size: 1rem,
                        font-weight: 400,
                        font-transform: initial
                      ),
                    // Arrow
                      arrow:
                      (
                        width: 20px,
                        transition: all 0.3s ease,
                        font-size: 0.6rem
                      ),
                    // Label
                      label:
                      (
                        padding: 0px 0px 0px 5px
                      )
                  ),
                // Submenu
                  submenu:
                  (
                    // Submenu
                      self:
                      (
                        indent: 15px,
                        margin: 0
                      ),
                    // Item
                      item:
                      (
                        // Item
                          self:
                          (
                            margin: 0
                          ),
                        // Link
                          link:
                          (
                            // Link
                              self:
                              (
                                height: 40px,
                                padding: 0 25px,
                                padding-x: 25px,
                                border-radius: $border-radius
                              ),
                            // Icon
                              icon:
                              (
                                width: 35px,
                                svg-width: 24px,
                                font-size: 1.3rem
                              ),
                            // Bullet
                              bullet:
                              (
                                self: (
                                  width: 15px
                                ),
                                dot: (
                                  size: 4px
                                ),
                                line: (
                                  width: 5px,
                                  height: 1px
                                )
                              ),
                            // Text
                              text:
                              (
                                font-size: 1rem,
                                font-weight: 400,
                                font-transform: initial
                              ),
                            // Arrow
                              arrow:
                              (
                                width: 20px,
                                transition: all 0.3s ease,
                                font-size: 0.6rem
                              ),
                            // Label
                              label:
                              (
                                padding: 0px 0px 0px 5px
                              )
                          )
                      ),
                    // Section
                      section:
                      (
                        self: (
                          indent: 15px,
                          padding: 0 25px,
                          margin: 20px 0 0 0,
                          height: 40px
                        ),
                        text: (
                          font-size: 0.8rem,
                          font-weight: 300,
                          font-transform: uppercase,
                          letter-spacing: 0.3px
                        ),
                        icon: (
                          font-size: 1.1rem
                        )
                      ),
                    // Separator
                      separator:
                      (
                        margin: 15px 0
                      )
                  )
              ),
            // Section
              section:
              (
                self: (
                  padding: 0 25px,
                  margin: 20px 0 0 0,
                  height: 40px
                ),
                text: (
                  font-size: 0.9rem,
                  font-weight: 500,
                  font-transform: uppercase,
                  letter-spacing: 0.3px
                ),
                icon: (
                  font-size: 1.1rem
                )
              ),
            // Separator
              separator:
              (
                margin: 15px 0
              )
          ),
        // Dropdown Mode
          dropdown:
          (
            // Item
              item:
              (
                // Item
                  self:
                  (
                    margin: 0,
                    zindex: 98,
                    // Aside's dropdown z-index has to be greater that fixed header's z-index,,,,,,,,,
                  ),
                // Submenu
                  submenu:
                  (
                    // Submenu
                      self:
                      (
                        padding: 20px 0,
                        width: 265px,
                        // Should be equal to the parent aside width
                          parent-width: 265px,
                        // Should be equal to the parent aside width
                          border-radius: 4px,
                        // Animation
                          animation:
                          (
                            offset: 10px
                          ),
                        // Vertical Offset
                          offset:
                          (
                            root: (
                              default: -5px,
                              up: -10px
                            ),
                            inner: (
                              default: -10px,
                              up: -10px
                            )
                          )
                      ),
                    // Item
                      item:
                      (
                        // Item
                          self:
                          (
                            margin: 0
                          ),
                        // Link
                          link:
                          (
                            // Link
                              self:
                              (
                                height: 40px,
                                padding: 7px 30px
                              ),
                            // Icon
                              icon:
                              (
                                width: 35px,
                                font-size: 1.35rem
                              ),
                            // Bullet
                              bullet:
                              (
                                self: (
                                  width: 15px
                                ),
                                dot: (
                                  size: 4px
                                ),
                                line: (
                                  width: 5px,
                                  height: 1px
                                )
                              ),
                            // Text
                              text:
                              (
                                font-size: 1rem,
                                font-weight: 400,
                                font-transform: initial
                              ),
                            // Arrow
                              arrow:
                              (
                                width: 20px,
                                transition: all 0.3s ease,
                                font-size: 0.6rem
                              ),
                            // Label
                              label:
                              (
                                padding: 0px 0px 0px 5px,
                                align: right
                              )
                          )
                      ),
                    // Section
                      section:
                      (
                        self: (
                          margin: 20px 0 0 0,
                          padding: 0 25px,
                          height: 40px
                        ),
                        text: (
                          font-size: 0.8rem,
                          font-weight: 300,
                          font-transform: uppercase
                        ),
                        icon: (
                          font-size: 1.1rem
                        )
                      ),
                    // Separator
                      separator:
                      (
                        margin: 15px 0
                      )
                  )
              )
          )
      ),
    // Theme
      theme:
      (
        // Default Mode
          default:
          (
            // Dropdown
              self: (),
            // Item
              item:
              (
                // Item
                  self:
                  (
                    bg-color: (
                      default: null,
                      open: null,
                      here: null,
                      active: null,
                      hover: null
                    )
                  ),
                // Link
                  link:
                  (
                    // Link
                      self:
                      (
                        bg-color: (
                          default: null,
                          open: null,
                          here: null,
                          active: $gray-100,
                          hover: null
                        )
                      ),
                    // Icon
                      icon:
                      (
                        font-color: (
                          default: $text-muted,
                          open: $primary,
                          here: $primary,
                          active: $primary,
                          hover: $primary
                        )
                      ),
                    // Bullet
                      bullet:
                      (
                        dot: (
                          bg-color: (
                            default: $text-muted,
                            open: $primary,
                            here: $primary,
                            active: $primary,
                            hover: $primary
                          )
                        ),
                        line: (
                          bg-color: (
                            default: $text-muted,
                            open: $primary,
                            here: $primary,
                            active: $primary,
                            hover: $primary
                          )
                        )
                      ),
                    // Text
                      text:
                      (
                        font-color: (
                          default: $dark-75,
                          open: $primary,
                          here: $primary,
                          active: $primary,
                          hover: $primary
                        )
                      ),
                    // Arrow
                      arrow:
                      (
                        font-color: (
                          default: $text-muted,
                          open: $primary,
                          here: $primary,
                          active: $primary,
                          hover: $primary
                        )
                      )
                  ),
                // Submenu
                  submenu:
                  (
                    // Submenu
                      self:
                      (
                        bg-color: (
                          default: null,
                          open: null,
                          active: null,
                          hover: null
                        )
                      ),
                    // Item
                      item:
                      (
                        // Item
                          self:
                          (
                            bg-color: (
                              default: null,
                              open: null,
                              here: null,
                              active: null,
                              hover: null
                            )
                          ),
                        // Link
                          link:
                          (
                            // Link
                              self:
                              (
                                bg-color: (
                                  default: null,
                                  open: null,
                                  here: null,
                                  active: $gray-100,
                                  hover: null
                                )
                              ),
                            // Icon
                              icon:
                              (
                                font-color: (
                                  default: $text-muted,
                                  open: $primary,
                                  active: $primary,
                                  hover: $primary
                                )
                              ),
                            // Bullet
                              bullet:
                              (
                                dot: (
                                  bg-color: (
                                    default: $text-muted,
                                    open: $primary,
                                    here: $primary,
                                    active: $primary,
                                    hover: $primary
                                  )
                                ),
                                line: (
                                  bg-color: (
                                    default: $text-muted,
                                    open: $primary,
                                    here: $primary,
                                    active: $primary,
                                    hover: $primary
                                  )
                                )
                              ),
                            // Text
                              text:
                              (
                                font-color: (
                                  default: $dark-75,
                                  open: $primary,
                                  active: $primary,
                                  hover: $primary
                                )
                              ),
                            // Arrow
                              arrow:
                              (
                                font-color: (
                                  default: $text-muted,
                                  open: $primary,
                                  active: $primary,
                                  hover: $primary
                                )
                              )
                          )
                      ),
                    // Section
                      section:
                      (
                        text: (
                          font-color: $dark-50
                        ),
                        icon: (
                          font-color: $text-muted
                        )
                      ),
                    // Separator
                      separator:
                      (
                        border: 1px solid $gray-200
                      )
                  )
              ),
            // Section
              section:
              (
                text: (
                  font-color: $dark-50
                ),
                icon: (
                  font-color: $text-muted
                )
              ),
            // Separator
              separator:
              (
                border: 1px solid $gray-200
              )
          )
      )
  )
) !default;

// Subheader
$subheader-config: (
  // Default Mode
    default:
    (
      bg-color: $white,
      border-color: $gray-200
    ),
  fixed: (
    // Fixed Subheader Mode
      height: 55px,
    shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.08),
    zindex: 95
  )
) !default;

// Footer
$footer-config: (
  // Fixed Footer Mode
    fixed:
    (
      zindex: 97,
      // Fixed footer's z-index
        height: 60px,
      // Fixed footer's height
        shadow: 0px 0px 28px 0px rgba(82, 63, 105, 0.13)
        // Fixed fooer box shadow,,,,,,,,,
    )
) !default;
