/** @format */

.card-container {
  display: flex;
  flex-wrap: wrap;
}

.card-container > div {
  flex-grow: 1;
}

.card-profile-contacts {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.card-profile {
  flex: 1;
}

.card-contacts {
  flex: 1;
}

.card-incorporation {
  flex: 1;
}

.card-financial-compliance {
  flex: 1 100%;
  display: flex;
  flex-direction: column;
}

.card-declaration {
  flex: 1 100%;
}

.card-box {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-box-body {
  min-height: 1px;
  padding: 1rem;
}

.card-entity-logo {
  padding: 10px 0;
}

.card-entity-name {
  padding: 15px 0;
}

.card-declaration-title {
  font-size: 20px;
}

.center-span {
  display: inline-block;
}

.card-field-value {
  font-size: 14px;
}

.procorp-card-title {
  color: rgb(50, 197, 168) !important;
}

.capitalise-letter {
  text-transform: capitalize;
}
