/** @format */

.modal-90p {
    width: 90vw;
    max-width: 90vw;
}

.modal-50p {
    width: 40vw;
    max-width: 40vw;
}
