/** @format */

.analytic-widget-card {
  // padding: 1.875rem;
  flex: 1 1 auto;
  margin-bottom: 10px;
}

.analytic-widget-card-container {
  background: #fff;
  position: relative;
  margin: 0 0.03rem;
  padding: 10px;
}

.analytic-table {
  border-radius: 10px;
  background-color: lightcyan;
  padding: 10px;
  margin-right: 20px;
  width: 100%;
  height: 100%;
}

.analytic-table-title {
  font-size: 20px;
  text-decoration: underline;
  padding-bottom: 5px;
  color: #09aa62;
}

.analytic-table-sub-title {
  padding-bottom: 5px;
}

.analytic-table-sub-title span {
  color: #1bbee4;
}

.analytic-table-content {
}

.analytic-small-box {
  height: 20px;
  width: 20px;
  display: inline-flex;
}

.analytic-details {
  padding-left: 5px;
}

.analytic-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.analytic-pagination-buttons {
  height: 47px;
  margin-bottom: 16px;
}
