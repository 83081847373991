/** @format */

.subheader-wrapper {
    border-left-style: solid;
    border-width: 4px;
    border-color: $tertiary;
    padding-left: 16px;
}

.procorp-content {
    background: rgb(50, 197, 168);
}

.procorp-content-grey {
    background: #eef0f8;
}

.full-screen-container {
    max-width: none !important;
    padding: 0px;
}

.public-page-title {
    font-size: 40px;
    font-weight: 800;
    font-style: italic;
}

.cursor-default {
    cursor: default !important;
}

.shadow-border {
    box-shadow: 0 1px 3px 0 rgba(63, 63, 68, 0.15);
}

.top-border {
    z-index: 100;
    border-top: 1px solid $gray-200;
    width: 100%;
}

.procorp-aside {
    background: #3f4254;
}

.procorp-btn-clean {
    color: #fff !important;
}

.btn.procorp-btn-gray {
    $btn-background: transparent;
    $btn-border: transparent;
    $btn-color: $gray-800;
    $btn-icon: #494b74;
    $btn-box-shadow: null;

    $btn-hover-background: $gray-900;
    $btn-hover-border: transparent;
    $btn-hover-color: $primary;
    $btn-hover-icon: $primary;
    $btn-hover-box-shadow: null;

    $btn-active-background: $btn-hover-background;
    $btn-active-border: $btn-hover-border;
    $btn-active-color: $btn-hover-color;
    $btn-active-icon: $btn-hover-icon;
    $btn-active-box-shadow: $btn-hover-box-shadow;

    @include button-custom-variant(
        $btn-background,
        $btn-border,
        $btn-color,
        $btn-icon,
        $btn-box-shadow,
        $btn-hover-background,
        $btn-hover-border,
        $btn-hover-color,
        $btn-hover-icon,
        $btn-hover-box-shadow,
        $btn-active-background,
        $btn-active-border,
        $btn-active-color,
        $btn-active-icon,
        $btn-active-box-shadow
    );
}

.procorp-aside-btn {
    width: 55px !important;
    height: 55px !important;
}

.procorp-aside-icon {
    color: inherit;
}

.page-card {
    padding: 1.875rem;
    flex: 1 1 auto;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.notification-title {
    font-size: 18px;
    font-weight: bold;
    color: red;
    border-bottom: 1px solid red;
    margin-bottom: 8px;
}

.notification-ul {
    padding-inline-start: 24px;
}

.notification-li {
    text-align: left;
}

.mt10px {
    margin-top: 10px;
}

.mx10px {
    margin-left: 10px;
    margin-right: 10px;
}

.procorp-menu-text {
    color: #e4e6ef !important;
    font-weight: 400 !important;
    font-size: 1.2rem !important;
    &.text-primary {
        color: #d3ae36 !important;
    }
}

.procorp-justify-center {
    width: 100%;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.procorp-symbol-label {
    background-color: unset !important;
}

.procorp-symbol-image {
    border-radius: 50%;
}

.procorp-lookup-title {
    color: #a1a1a6;
}

.procorp-hero {
    background-image: url(/media/bg/bg-9.jpg);
}

.procorp-dashboard {
    background-size: cover;
    background-position: center;
    background-image: url(/media/bg/bg-9.jpg);
    min-height: 100vh;
}

.procorp-address-button {
    max-width: 100px;
}

.procorp-small-input {
    // max-width: 150px;
}

.procorp-text-white {
    color: #fff !important;
}

.cd-icon-small {
    height: 40px !important;
    line-height: 40px !important;
}
