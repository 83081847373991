/** @format */

// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

// Custom card styles
@import './scss/button-card.scss';
@import './scss/footer.scss';
@import './scss/layout.scss';
@import './scss/input.scss';
@import './scss/table.scss';
@import './scss/utils.scss';
@import './scss/dashboard.scss';
@import './scss/print.scss';
@import './scss/invoice.scss';
@import './scss/dialog.scss';
@import './scss/entity-card.scss';
@import './scss/analytic.scss';
@import './scss/breadcrumb.scss';
@import './scss/card.scss';

// Default Layout themes
// @import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
// .brand-dark {
//   @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// }
// // Light
// .brand-light {
//   @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
// }

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
    background: none transparent !important;
    margin: 0 !important;
    padding-top: 0.5rem;

    code {
        overflow-y: auto;
        display: block;

        span {
            background: none transparent !important;
        }
    }
}

.json > pre {
    background: none #fff !important;
}

.example-code > .json {
    background: none #fff !important;
}

.symbol.symbol-45 > svg {
    width: 100%;
    max-width: 45px;
    height: 45px;
}
