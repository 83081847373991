/** @format */

.print-main-body {
  padding: 15px;
}

.print-card {
  // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.print-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.print-card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.print-gutters-sm {
  margin-right: -8px;
  margin-left: -8px;
}

.print-gutters-sm > .col,
.print-gutters-sm > [class*="col-"] {
  padding-right: 8px;
  padding-left: 8px;
}

.print-mb-3,
.print-my-3 {
  margin-bottom: 1rem !important;
}

.print-mb-0 {
  margin-bottom: 0;
}

.print-bg-gray-300 {
  background-color: #e2e8f0;
}

.print-h-100 {
  height: 100% !important;
}

.print-shadow-none {
  box-shadow: none !important;
}

.print-hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.print-hr-field {
  margin-top: 4px;
  margin-bottom: 4px;
}

.print-h6 {
  font-size: 1rem;
}

.print-h4 {
  font-size: 1.25rem;
}
