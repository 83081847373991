//
// Pulse
//

// Base
.pulse {
  position: relative;

  .pulse-ring {
    display: block;
    border-radius: 40px;
    height: 40px;
    width: 40px;
    position: absolute;
    animation: animation-pulse 3.5s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    border-width: 3px;
    border-style: solid;
    border-color: $gray-300;

    @include for-ie11 {
      top: 2px;
      left: 2px;
    }
  }

  // Theme Colors
  @each $name, $color in $theme-colors {
    &.pulse-#{$name} {
      .pulse-ring {
        border-color: rgba($color, 0.75);
      }
    }
  }
}

@keyframes animation-pulse {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  60% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
