/** @format */

.dashboard-card {
  .dashboard-title {
    color: #3f4254;
  }

  .dashboard-content {
    color: #3f4254;
  }

  .dashboard-card-icon {
    @include svg-icon-color($gray-500);
  }
}

.dashboard-card:hover {
  .dashboard-title {
    color: $primary;
  }

  .dashboard-content {
    color: $primary;
  }

  .dashboard-card-icon {
    @include svg-icon-color($primary);
  }
}

.dashboard-subheading {
  font-style: italic;
  font-weight: 400;
  color: $gray-700;
  margin-bottom: 0.4rem;
}

.dashboard-about-heading {
  font-weight: 500;
  color: $gray-900;
  margin-bottom: 2rem;
}

.dashboard-about-content {
  color: $gray-600;
  margin-bottom: 2rem;
  text-align: left;
}

.dashboard-service-card {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1.5rem;
  border-radius: 10px;
  background-color: $primary;
  color: #ffffff;
}
