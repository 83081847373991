//
// Dropdown
//

// Base
.dropdown-menu {
  &:before,
  &:after {
    display: none !important;
  }

  > li > a,
  > .dropdown-item {
    outline: none !important;
    display: flex;
    flex-grow: 1;

    > i {
      &:before {
        line-height: 0;
        vertical-align: middle;
      }
    }
  }

  // RTL mode fix
  .dropdown-menu:not(.daterangepicker) {
    /*rtl:raw:
        left: auto !important;
        */
  }

  // Animations
  &.dropdown-menu-anim-up {
    animation: animation-dropdown-menu-fade-in 0.3s ease 1,
      animation-dropdown-menu-move-up 0.3s ease-out 1;
  }

  .dropup &,
  .dropdown-menu-anim-down {
    &.dropdown-menu-anim {
      animation: animation-dropdown-menu-fade-in 0.3s ease 1,
        animation-dropdown-menu-move-down 0.3s ease-out 1;
    }
  }

  // Alignment
  left: 0#{"/*rtl:ignore*/"};
  /*rtl:raw:
    right: auto;
    */
}

// Widths
.dropdown-menu {
  // Global max-width For Mobile Mode
  @media (max-width: get($dropdown-menu-widths, xxl)) {
    width: 75vw;
  }

  @each $name, $width in $dropdown-menu-widths {
    &.dropdown-menu-#{$name} {
      @media (min-width: $width + 30px) {
        width: $width;
      }
    }
  }
}

// Inline
.dropdown-inline {
  display: inline-block;
}

// Hover
.dropdown {
  &.dropdown-hover:hover {
    > .dropdown-menu {
      display: block;

      &.dropdown-menu-right {
        left: auto;
        right: 0;
      }
    }

    > .dropdown-toggle:active {
      /*Without this, clicking will make it sticky*/
      pointer-events: none;
    }
  }
}

// Animations
@keyframes animation-dropdown-menu-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes animation-dropdown-menu-move-down {
  from {
    margin-top: 10px;
  }
  to {
    margin-top: 0px;
  }
}

@keyframes animation-dropdown-menu-move-up {
  from {
    margin-top: 10px;
  }
  to {
    margin-top: 0px;
  }
}
