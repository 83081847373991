/** @format */

.button-card {
    margin-bottom: 1.875rem;
    background-color: white; // background-color: #f4f4f4;
    transition: all 0.5s ease-in-out;
    position: relative;
    border: 0 solid transparent;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05); // box-shadow: 0 12px 23px 0 rgba(51, 49, 49, 0.33);
    height: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: initial;
    cursor: pointer;
}

.button-header-card {
    margin-bottom: 1.875rem;
    background-color: $primary; // background-color: #f4f4f4;
    transition: all 0.5s ease-in-out;
    position: relative;
    border: 0 solid transparent;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05); // box-shadow: 0 12px 23px 0 rgba(51, 49, 49, 0.33);
    height: calc(100% - 30px);
    // display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: initial;
    cursor: pointer;
}

.button-orgchart-card {
    background-color: #f4f4f4;
    transition: all 0.5s ease-in-out;
    position: relative;
    border: 0 solid transparent;
    border-radius: 0.5rem;
    box-shadow: 0 12px 23px 0 rgba(51, 49, 49, 0.33);
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: initial;
    cursor: pointer;
}

.entityrecord-card {
    background-color: #ffffff;
    transition: all 0.5s ease-in-out;
    position: relative;
    border: 0 solid transparent;
    border-radius: 0.5rem;
    box-shadow: 0 2px 5px 0 rgba(51, 49, 49, 0.33);
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: initial;
    cursor: pointer;
}

.button-orgchart-card-body {
    padding: 1rem;
    flex: 1 1 auto;
    min-height: 1px;
}

.button-card-body {
    padding: 1rem;
    flex: 1 1 auto;
    min-height: 1px;
}

.button-media {
    display: flex;
    align-items: flex-start;
}

.button-media-body {
    flex: 1;
}

.button-mr-3 {
    margin-right: 1rem !important;
}

.button-description-position {
    font-size: 14px;
}

.button-small-description-position {
    font-size: 14px;
}

.button-orgchart-description-position {
    font-size: 14px;
}

.cd-icon {
    height: 70px;
    width: 70px;
    line-height: 70px;
    min-width: 70px;
    text-align: center;
    border-radius: 1.25rem;
}

.button-line {
    height: 4px;
    border-radius: 1.25rem;
    width: 95%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.text-success {
    color: #2bc155 !important;
}

.bgl-success {
    background: #e3f9e9;
    border-color: #e3f9e9;
}

.bg-success {
    background-color: #2bc155 !important;
}

.text-secondary {
    color: #3f9ae0 !important;
}

.bgl-secondary {
    background: #ddedfa;
    border-color: #ddedfa;
}

.bg-secondary {
    background-color: #3f9ae0 !important;
}

.text-warning {
    color: #ff9b52 !important;
}

.bgl-warning {
    background: #fff3eb;
    border-color: #fff3eb;
}

.bg-warning {
    background-color: #ff9b52 !important;
}

.text-info {
    color: #8743df !important;
}

.bgl-info {
    background: #ffbbff;
    border-color: #ffbbff;
}

.text-other {
    color: #6993ff !important;
}

.bgl-other {
    background: #e1e9ff;
    border-color: #e1e9ff;
}

.bg-info {
    background-color: #8743df !important;
}

.card-icon {
    width: 80%;
}

.font-15 {
    font-size: 15px !important;
}

.flow-chart-button {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.float-right {
    float: right;
}

.fa-button-icon {
    color: #d3ae36;
}
